<!--
 * @Auth: linjituan
 * @Date: 2021-09-29 19:13:03
 * @LastEditors: linjituan
 * @LastEditTime: 2021-10-18 20:18:08
-->
<template>
  <a-result status="500" title="500" sub-title="Sorry, the server is reporting an error.">
    <template #extra>
      <a-button type="primary" @click="toHome">
        Back Home
      </a-button>
    </template>
  </a-result>
</template>

<script>
import { Result } from 'ant-design-vue'
  export default {
    name: 'Exception500',
    components: {
      AResult: Result
    },
    methods: {
      toHome () {
        this.$router.push({ path: '/' })
      }
    }
  }
</script>
