var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-result", {
    attrs: {
      status: "500",
      title: "500",
      "sub-title": "Sorry, the server is reporting an error.",
    },
    scopedSlots: _vm._u([
      {
        key: "extra",
        fn: function () {
          return [
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.toHome } },
              [_vm._v(" Back Home ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }